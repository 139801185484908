<template>
  <b-card>
    <FormView
      :data="$store.getters['highlight/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'imgUrl',
          label: 'Image',
          type: 'image',
        },
        {
          key: 'featuredImage',
          label: 'Featured Image',
          type: 'image',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'description',
          label: 'Description',
          visibility: ['view'],
        },
        {
          key: 'url',
          label: 'Url',
          visibility: ['view'],
        },
        {
          key: 'sort',
          label: 'Sort',
          prefix: '#',
        },
        {
          key: 'rule.countries',
          label: 'Countries',
          type: 'countries',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('highlight/getDetail', id)
    },
  },
}
</script>

<style scoped>

</style>
